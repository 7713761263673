<template>
  <div class="UnreadMeter">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <v-btn
          color="primary"
          outlined
          class="mb-5"
          @click="showFetchMeterReadingsDialog = true"
        >
          Fetch Meter Readings
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="can('meter-reading-create')"
          color="primary"
          class="mb-5"
          @click="showAddUnreadMeterDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiShapeSquarePlus }}
          </v-icon>
          Add Unread Meter
        </v-btn>
      </v-col>
    </v-row>
    <unread-meters-table
      :meter-reading-url="meterReadingUrl"
      :show-add-meter-dialog="showAddUnreadMeterDialog"
      @add-meter-dialog-open="showAddUnreadMeterDialog = true"
      @add-meter-dialog-close="showAddUnreadMeterDialog = false"
    />
    <confirm-dialog
      :show-dialog="showFetchMeterReadingsDialog"
      :is-agree-button-loading="isFetchingMeterReadings"
      :message="`Are you sure you want to fetch meter readings for ${new Date().toLocaleString('default', { month: 'long' })}?`"
      :agree-text="'Fetch'"
      @cancel="showFetchMeterReadingsDialog = false"
      @agree="fetchMeterReadings()"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiShapeSquarePlus,
} from '@mdi/js'
import axios from 'axios'
import hasPermission from '@/mixins/hasPermission'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import UnreadMetersTable from '@/components/tables/UnreadMetersTable.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    ConfirmDialog,
    'unread-meters-table': UnreadMetersTable,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddUnreadMeterDialog: false,
      showFetchMeterReadingsDialog: false,
      isFetchingMeterReadings: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Unread Meters',
          disabled: true,
          to: { name: 'unread-meters' },
        },
      ],
      icons: {
        mdiShapeSquarePlus,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    meterReadingUrl() {
      return `unread-meters?station_id=${this.selectedStation}`
    },
  },
  methods: {
    fetchMeterReadings() {
      this.isFetchingMeterReadings = true
      axios
        .get('monthly-meter-readings/fetch')
        .then(response => {
          this.$notification.success(response.data.message)
        })
        .catch(error => {
          console.log('🚀 ~ file: UnreadMeters.vue:108 ~ fetchMeterReadings ~ error:', error)
          this.$notification.error(error.data.message)
        })
        .finally(() => {
          this.isFetchingMeterReadings = false
          this.showFetchMeterReadingsDialog = false
        })
    },
  },
}
</script>
